// src/OptOutForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { redirect } from "react-router-dom";

const OptOutForm = () => {
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [option, setOption] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!emailOrPhone) {
            alert('Please enter an email or phone number.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[+]?[0-9\s]+$/;

        if (emailOrPhone.includes('@') && !emailRegex.test(emailOrPhone)) {
            alert('Please enter a valid email address.');
            return;
        } else if (!emailOrPhone.includes('@') && !phoneRegex.test(emailOrPhone)) {
            alert('Please enter a valid phone number.');
            return;
        }

        let optionsToPost = [];
        if ( option.Email ) {
            optionsToPost.push('Email');
        }

        if ( option.SMS ) {
            optionsToPost.push('SMS');
        }

        if (optionsToPost.length === 0) {
            alert('Please select an option.');
            return;
        }

        try {
            const formData = { emailOrPhone, optionsToPost };
            await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/submit-form`, formData);
            // move to /success
            window.location.href = '/success';
            // history.push('/success')
            // return redirect("/success");
        } catch (error) {
            alert('An error occurred while submitting the form.');
            console.error('Submission error:', error);
        }
    };

    return (
        <div id="outerwrapper">
            <div id="wrapper">
                <div id="header">
                    <h2>Marketing Opt Out</h2>
                </div>
                <form id="infoForm" onSubmit={handleSubmit}>
                    <label>
                        <strong>Please enter EITHER your mobile number OR email address. <span className="requiredStar">*</span></strong><br />
                        Incorrect entry will result in the request not being processed.
                    </label><br />
                    <input
                        type="text"
                        id="emailOrPhone"
                        name="emailOrPhone"
                        value={emailOrPhone}
                        onChange={e => setEmailOrPhone(e.target.value)}
                    /><br />
                    <p>Please Check methods of contacts for Opt Out <span className="requiredStar">*</span></p>
                    <div className="opt-out-method">
                        <input
                            type="checkbox"
                            id="Email"
                            name="option[]"
                            value="Email"
                            onChange={e => setOption({ ...option, Email: (e.target.checked) })}
                        />
                        <label htmlFor="Email">Email</label><br />
                        <input
                            type="checkbox"
                            id="SMS"
                            name="option[]"
                            value="SMS"
                            onChange={e => setOption({ ...option, SMS: (e.target.checked) })}
                        />
                        <label htmlFor="SMS">SMS</label><br />
                    </div>
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    );
};

export default OptOutForm;

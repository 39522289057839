// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import './App.css';
import OptOutForm from './OptOutForm';
import SuccessPage from './SuccessPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/success" element={<SuccessPage />} />
          <Route path="*" element={<OptOutForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
// src/OptOutForm.js
import React, { useState } from 'react';

const SuccessPage = () => {
    return (
        <div id="outerwrapper">
            <div id="wrapper">
                <div id="header">
                    <h2>Opt Out Successful!</h2>
                </div>
                <div className="opt-out-success">
                    You have successfully opted out!
                </div>
            </div>
        </div>
    );
}

export default SuccessPage;